import * as React from "react"

import './styles.css'

const Presentation = () => {
  return (
      <div className="presentation">
        <div className="presentation-content">
          <div className="presentation-text">
            <h1 className="presentation-title">Reitec Engenharia</h1>
            <p className="presentation-description">Melhoria contínua é um fator que a Reitec Engenharia incorpora como um de seus principais objetivos!</p>
          </div>
        </div>
      </div>
  )
}

export default Presentation