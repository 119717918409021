import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import "./styles.css";

const TypeServices = () => {
  return (
    <div id="type-service">
      <h1>Serviços</h1>
      <section>

      <div className="type-service-container">
          <div className="type-service-title" style={{ textAlign: "right" }}>
            <h2>
              Manutenção em torres de telecomunicações
            </h2>
            <p>Manutenção Preventiva, Corretiva e Preditiva.</p>
            <div>
              <a href="/#contact">FAZER ORÇAMENTO</a>
            </div>
          </div>
          <div className="type-service-img">
            <StaticImage
              src="../../images/services/telecomunicacao.jpg"
              alt="Telecomunicação"
              layout="fullWidth"
            />
          </div>
        </div>

        <div className="type-service-container">
          <div className="type-service-img">
            <StaticImage
              src="../../images/services/fibra.png"
              alt="Fibra Optica"
              layout="fullWidth"
            />
          </div>
          <div className="type-service-title">
            <h2>Serviço de infraestrutura em fibra óptica</h2>
            <p>Implantação e manutenção de redes de fibra óptica.</p>
            <div>
              <a href="/#contact">FAZER ORÇAMENTO</a>
            </div>
          </div>
        </div>

        <div className="type-service-container">
          <div className="type-service-title" style={{ textAlign: "right" }}>
            {/*
              <h2>Jardinagem, limpeza e conservação predial</h2>
                <p>
                  Manter um ambiente limpo e organizado permite transmitir uma
                  imagem positiva para todos os que frequentam sua empresa.
                </p>
                <div>
                  <a href="/#contact">FAZER ORÇAMENTO</a>
                </div>
              </div>
              <div className="type-service-img">
                <img src={cleaning} alt="Limpeza" />
              </div>
            */}
            <h2>
              Elaboração e execução de projetos de sistemas de energia solar
              fotovoltaico
            </h2>
            <p>Microgeração e Minigeração.</p>
            <div>
              <a href="/#contact">FAZER ORÇAMENTO</a>
            </div>
          </div>
          <div className="type-service-img">
            <StaticImage
              src="../../images/services/solar.png"
              alt="Placas Solares"
              layout="fullWidth"
            />
          </div>
        </div>

        <div className="type-service-container">
          <div className="type-service-img">
            <StaticImage
              src="../../images/services/camera.png"
              alt="Câmeras de Segurança"
              layout="fullWidth"
            />
          </div>
          <div className="type-service-title">
            <h2>Sistemas de CFTV</h2>
            <p>Venda, instalação e manutenção.</p>
            <div>
              <a href="/#contact">FAZER ORÇAMENTO</a>
            </div>
          </div>
        </div>

        <div className="type-service-container">
          <div className="type-service-title" style={{ textAlign: "right" }}>
            <h2>Climatizadores evaporativo</h2>
            <p>Venda, instalação e manutenção.</p>
            <div>
              <a href="/#contact">FAZER ORÇAMENTO</a>
            </div>
          </div>
          <div className="type-service-img">
            <StaticImage
              src="../../images/services/evaporative.png"
              alt="Climatização Evaporativa"
              layout="fullWidth"
            />
          </div>
        </div>

        <div className="type-service-container">
          <div className="type-service-img">
            <StaticImage
              src="../../images/services/generator.png"
              alt="Gerador"
              layout="fullWidth"
            />
          </div>
          <div className="type-service-title">
            <h2>Geradores de energia</h2>
            <p>
              Venda, instalação e manutenção.
            </p>
            <div>
              <a href="/#contact">FAZER ORÇAMENTO</a>
            </div>
          </div>
        </div>

        <div className="type-service-container">
          <div className="type-service-title" style={{ textAlign: "right" }}>
            <h2>Sistemas de climatização</h2>
            <p>Instalação e manutenção.</p>
            <div>
              <a href="/#contact">FAZER ORÇAMENTO</a>
            </div>
          </div>
          <div className="type-service-img">
            <StaticImage
              src="../../images/services/climate.png"
              alt="Climatização"
              layout="fullWidth"
            />
          </div>
        </div>

        <div className="type-service-container">
          <div className="type-service-img">
            <StaticImage
              src="../../images/services/battery.png"
              alt="Bateria"
              layout="fullWidth"
            />
          </div>
          <div className="type-service-title">
            <h2>Implantação e manutenção de sistemas de energia AC/DC</h2>
            <p>
              Instalação de fontes de corrente contínua, bancos de baterias,
              QDCA's, QDCC's, inversores e nobreak's.
            </p>
            <div>
              <a href="/#contact">FAZER ORÇAMENTO</a>
            </div>
          </div>
        </div>

        <div className="type-service-container">
          {/*<div className="type-service-title" style={{ textAlign: "right" }}>
            <h2>Serviços de manutenção e reparos civil</h2>
            <p>
              Pintura, Drywall, trocas de fechaduras, serralheria, marcenaria,
              elétrica, hidráulica e reforma predial.
            </p>
            <div>
              <a href="/#contact">FAZER ORÇAMENTO</a>
            </div>
          </div>
          <div className="type-service-img">
            <img src={civil} alt="Civil" />
          </div>*/}
          <div className="type-service-title" style={{ textAlign: "right" }}>
            <h2>Serviços de reforma e manutenção predial</h2>
            <p>
              Serviços de reforma predial em geral, como manutenção de telhados
              e fachadas, pintura, revestimentos, elétrica, blindex, drywall, toldo de policarbonato,
              calhas e rufos, entre outros.
            </p>
            <div>
              <a href="/#contact">FAZER ORÇAMENTO</a>
            </div>
          </div>
          <div className="type-service-img">
            <StaticImage
              src="../../images/services/civil.png"
              alt="Civil"
              layout="fullWidth"
            />
          </div>
        </div>

        <div className="type-service-container">
          <div className="type-service-img">
            <StaticImage
              src="../../images/services/solder.png"
              alt="Solda"
              layout="fullWidth"
            />
          </div>
          <div className="type-service-title">
            <h2>Serviços de Caldeiraria</h2>
            <p>
              Serviços de confecção, manutenção, eleboração e reparos com soldas
              e instrumentos metálicos.
            </p>
            <div>
              <a href="/#contact">FAZER ORÇAMENTO</a>
            </div>
          </div>
        </div>

        {/*<div className="type-service-container">
          <div className="type-service-title" style={{ textAlign: "right" }}>
            <h2>Fachadas em ACM</h2>
            <p>Instalação de fachadas em ACM</p>
            <div>
              <a href="/#contact">FAZER ORÇAMENTO</a>
            </div>
          </div>
          <div className="type-service-img">
            <img src={facades} alt="Climatização" />
          </div>
        </div>*/}
      </section>
    </div>
  );
};

export default TypeServices;
